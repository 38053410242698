<template>
  <div style="background: #fff;color: #000;height: 100%;">
    <van-nav-bar title="验车预约" left-arrow :fixed="true" :z-index="999" @click-left="back" />
    <div class="hserd">
      <img class="jiantu" src="../../assets/img/jiantu.jpg" alt />
      <div class="shoutop">
        <div class="left shou cheng">
          <span style="vertical-align: middle;">所有城市</span>
          <van-icon style="vertical-align: middle;" color="#7232dd" name="arrow-down" />
        </div>
        <input type="text" v-model="search" placeholder="检测站名称关键字" class="left shou guan" />
        <div class="right shousuo" @click="namelist">搜索</div>
      </div>
    </div>
    <div style="height: 100%;background: #fff;" class="hedss">
      <!-- @click="tomendian(item)" -->
      <div class="m_list" v-for="item in list" :key="item.id">
        <div class="left heb">
          <div style="max-height: 50px">
            <span style="vertical-align: middle;font-weight: bold;line-height: 18px;">{{item.companyName}}</span>
            <span class="biaoshi">
              <span class="gcd">检</span>
            </span>
          </div>
          <div class="hename" style="margin-top: 8px;">电话：{{item.phone}}</div>
          <div class="hename">地址：{{item.address}}</div>
        </div>
        <div class="left hea">
          <div style="text-align: right;height: 20px;" @click="daohang(item)">
            <img class="jdhang" src="../../assets/img/jiandaohang.png" alt />
          </div>
          <div style="font-size: 12px;line-height: 20px;" class="right" @click="daohang(item)">
            <span style="vertical-align: middle;color: #666;">{{item.juli}}km</span>
          </div>
          <van-button color="#0d906e" class="clsyuyue" size="small" @click="toyuyue(item)">马上预约</van-button>
        </div>
      </div>
      <div style="height: 60px;width: 100%;"></div>
    </div>

    <tabbarcar :active="1"></tabbarcar>
  </div>
</template>

<script>
import userModel from "../../api/user";
import tabbarcar from "../../components/tabbarcar.vue";
import { companylist } from "../../api/check.js";
export default {
  components: {
    tabbarcar
  },
  data() {
    return {
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      isok: this.$route.query.isok,
      search:'',
      login: this.$route.query.login,
      title: this.$route.query.title,
     
      showisok: this.$route.query.showisok,
      jd: JSON.parse(localStorage.getItem("JD")),
      wd: JSON.parse(localStorage.getItem("WD")),
      list: []
    };
  },
  methods: {
    toyuyue(item) {
      this.$router.push({
        path: "Vehicle_car",
        query: {
          cid: item.id,
          fee:item.oneFee,
          companyName:item.companyName
        }
      });
    },
    namelist() {
      var data = {
        search: this.search
        // gid: 223
      };
      companylist(data)
        .then(e => {
          // loading.clear();
          this.list = e.data;
          if (this.list.length > 0) {
            this.list.forEach((item, index) => {
              if (JSON.parse(localStorage.getItem("JD")) != null) {
                if (item.positionWd != null) {
                  var radLat1 = (this.wd * Math.PI) / 180.0;
                  var radLat2 = (item.positionWd * Math.PI) / 180.0;
                  var a = radLat1 - radLat2;
                  var b =
                    (this.jd * Math.PI) / 180.0 -
                    (item.positionJd * Math.PI) / 180.0;
                  var s =
                    2 *
                    Math.asin(
                      Math.sqrt(
                        Math.pow(Math.sin(a / 2), 2) +
                          Math.cos(radLat1) *
                            Math.cos(radLat2) *
                            Math.pow(Math.sin(b / 2), 2)
                      )
                    );
                  s = s * 6378.137; // EARTH_RADIUS;
                  s = Math.round(s * 10000) / 10000;
                  var juli = s.toFixed(2);
                  item.juli = juli;
                } else {
                  item.juli = 999.0;
                }
              }
            });
          }
          if (this.list.length > 1) {
            this.list.sort((old, New) => {
              console.log(old);
              console.log(New);
              return old.juli - New.juli;
            });
          }
          console.log(this.list);
        })
        .catch(() => loading.clear());
    },
        //导航
    daohang(item) {
      var positionWd = Number(item.positionWd);
      var positionJd = Number(item.positionJd);
      wx.openLocation({
        latitude: positionWd, // 纬度，浮点数，范围为90 ~ -90
        longitude: positionJd, // 经度，浮点数，范围为180 ~ -180。
        name: item.companyName, // 位置名
        address: item.province+item.city+item.district+item.address, // 地址详情说明
        scale: 13, // 地图缩放级别,整形值,范围从1~28。默认为最大
        infoUrl: "" // 在查看位置界面底部显示的超链接,可点击跳转
      });
    },
    getConfig() {
      let that = this;
      userModel
        .config({
          url: location.href.split("#")[0],
          gid: sessionStorage.getItem("gid")
        })
        .then(res => {
          if (res.code == 200) {
            console.log(res);
            wx.config({
              debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
              appId: res.data.appid, // 必填，公众号的唯一标识
              timestamp: res.data.timestamp, // 必填，生成签名的时间戳
              nonceStr: res.data.noncestr, // 必填，生成签名的随机串
              signature: res.data.signature, // 必填，签名
              jsApiList: ["getLocation", "onMenuShareAppMessage",'openLocation']
            });
            // wx.ready(() => {
            //   wx.onMenuShareAppMessage({
            //     title: "驿车驹门店",
            //     desc: "邀请您使用驿车驹在线服务！",
            //     link:
            //       "https://shopping.car-posthouse.cn/user?gid=" +
            //       sessionStorage.getItem("Agid"),
            //     imgUrl:
            //       "https://shopping.car-posthouse.cn/assets/img/fuwu1.2584c349.png",
            //     success: function() {}
            //   });
            // });
            wx.ready(() => {
              wx.getLocation({
                type: "wgs84", // 默认为wgs84的gps坐标，如果要返回直接给openLocation用的火星坐标，可传入'gcj02'
                success: function(res) {
                  console.log(res);
                  var latitude = res.latitude; // 纬度，浮点数，范围为90 ~ -90
                  var longitude = res.longitude; // 经度，浮点数，范围为180 ~ -180。
                  localStorage.setItem("JD", res.longitude);
                  localStorage.setItem("WD", res.latitude);
                  var speed = res.speed; // 速度，以米/每秒计
                  var accuracy = res.accuracy; // 位置精度
                }
              });
            });
          } else {
            this.$toast(res.data.msg);
          }
        });
    },
    back() {
      this.$router.go(-1);

    }
  },
  created() {
    if (this.$route.query.denglu != undefined) {
      var url =
        this.$route.path +
        "?gid=" +
        this.$route.query.gid +
        "&isok=" +
        true +
        "&login=" +
        true;
      localStorage.setItem("beforeUrl", url);
      sessionStorage.setItem("Agid", this.$route.query.gid);
      sessionStorage.setItem("gid", this.$route.query.gid);
      userModel
        .getGarageInfo({ gid: this.$route.query.gid })
        .then(e => {
          // loading.clear();
          this.shangjia = e.data;
          sessionStorage.setItem("appid", e.data.idd);
          sessionStorage.setItem("shangjia", JSON.stringify(this.shangjia));
          // 正式
          const redirect_uri = encodeURIComponent(
            "https://shopping.car-posthouse.cn/auths"
          );
          window.location.href = `https://marketing.car-posthouse.cn/get-weixin-code.html?appid=${e.data.idd}&redirect_uri=${redirect_uri}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`;
        })
        .catch(() => loading.clear());
    }
    // localStorage.setItem("JD", "113.891656");
    // localStorage.setItem("WD", "22.78564");
    this.getConfig();
    this.namelist();
  }
};
</script>
<style lang="less" scoped>
.m_list {
  overflow: hidden;
  padding: 10px 10px;
  border-bottom: 1px solid #f5f5f5;
  font-size: 15px;
}

.m_img {
  width: 70px;
  height: 70px;
}
.moren_img {
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
}

.hea {
  width: 25%;
}
.biaoshi {
  display: inline-block;
  background: #8fcab8;
  font-size: 12px;
  color: #fff;
  line-height: 17px;
  padding: 0 0.5px;
  border-radius: 2px;
  margin-top: 1px;
  vertical-align: middle;
  margin-left: 5px;
}
.heb {
  width: 75%;
}
.gc {
  display: inline-block;
  color: #fff;
  background: red;
  font-size: 12px;
}
.gcd {
  padding: 1px 2px;
  display: inline-block;
  transform: scale(0.9);
}
.cod {
  vertical-align: middle;
}
.hserd {
  position: fixed;
  top: 0;
  left: 0;
  margin-top: 46px;
  width: 100%;
  z-index: 9;

  background: #fff;
}
.shoutop {
  height: 45px;
  overflow: hidden;
  padding: 5px 12px;
  border-bottom: 1px solid #f5f5f5;
}
.jiantu {
  width: 100%;
}
.shou {
  border: 1px solid #dcdcdc;
  line-height: 34px;
  height: 34px;
  border-radius: 5px;
}
.cheng {
  width: 26%;
  margin-right: 10px;
  padding: 0 8px;
  font-size: 14px;
  line-height: 30px;
}
.hedss {
  margin-top: 264px;
}
.guan {
  width: 45%;
  padding: 0 10px;
  font-size: 14px;
}
.shousuo {
  width: 20%;
  text-align: center;
  background: #f5f5f5;
  line-height: 34px;
  height: 34px;
  border-radius: 5px;
  font-size: 15px;
}
.hename {
  color: #666;
  font-size: 14px;
  line-height: 22px;
}
.clsyuyue {
  float: right;
  width: 90%;
  margin-top: 6px;
}
.jdhang {
  // display: block;
  width: 20px;
  // float: right;
}
</style>